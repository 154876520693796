"use client";
import {
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
  MutableRefObject,
} from "react";
import Image from "next/image";
import { Link } from "@/components/Link";
import useFramerMotion, {
  useInView,
  LazyMotion,
  AnimatePresence,
  m,
  animate,
  useAnimate,
} from "@/hooks/useFramerMotion";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { useSwipeable } from "react-swipeable";
import { useArrowKey } from "@/hooks/useArrowKey";
import { useTranslations } from "next-intl";
import { useLocale } from "next-intl";
import { Locale } from "@/i18n/routing";
import { items } from "@/data/Testimonials";

type CardLocale = {
  title: string;
  quote: string;
};
type Card = {
  id: number;
  name: string;
  de: CardLocale;
  en: CardLocale;
  company: string;
  photo?: string;
  link: string;
};

type playbackControls = ReturnType<typeof animate> | null;

type ClientInfoProps = Card & {
  title: string;
  timeout: number;
  controls: MutableRefObject<playbackControls>;
};

type SpinnerProps = {
  timeout: number;
  controls: MutableRefObject<playbackControls>;
};
const slideVariants = {
  center: {
    x: 0,
    opacity: 1,
    transition: {
      x: { type: "spring", duration: 0.4 },
      opacity: {
        duration: 0.5,
      },
    },
  },
  enter: (direction: number) => {
    return {
      x: direction > 0 ? "100vw" : "-100vw",
      opacity: 0,
      transition: {
        x: { type: "spring", duration: 0.8 },
        opacity: { duration: 0.4 },
      },
    };
  },
  leave: (direction: number) => {
    return {
      x: direction < 0 ? "100vw" : "-100vw",
      opacity: 0,
      transition: {
        x: { type: "spring", duration: 0.8 },
        opacity: { duration: 0.4 },
      },
    };
  },
};

const TestimonialsSwiper = ({ timeout = 10000 }: { timeout?: number }) => {
  const framerMotionFeatures = useFramerMotion();
  const locale = useLocale() as Locale;
  const ref = useRef(null);
  const { sortedCards, numCards } = useMemo(() => {
    const sortedCards = items.sort(() => Math.random() - 0.5);
    const numCards = sortedCards.length;
    return { sortedCards, numCards };
  }, []);
  const currentIndex = useRef(0);
  const [card, setCard] = useState<Card | null>(null);

  const [paused, setPaused] = useState(true);
  const [direction, setDirection] = useState(1);

  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const startTime = useRef(0);
  const controls = useRef<playbackControls>(null);
  const [cardTimeout, setCardTimeout] = useState(timeout);
  const remainingTime = useRef(cardTimeout);
  const isInView = useInView(ref, { amount: "some" });

  const calculateReadingTime = () => {
    if (card?.[locale].quote) {
      const quote = card[locale].quote;
      const cardLength = quote.length;
      const readingTime = (cardLength / 5) * 100 + timeout;
      setCardTimeout(readingTime);
    }
  };

  const prev = useCallback(() => {
    setDirection(-1);
    let prevItemIndex = currentIndex.current - 1;
    if (!sortedCards[prevItemIndex]) {
      prevItemIndex = numCards - 1;
    }
    setCard(sortedCards[prevItemIndex]);
    currentIndex.current = prevItemIndex;
  }, [numCards, sortedCards]);

  const next = useCallback(() => {
    setDirection(1);
    let nextItemIndex = currentIndex.current + 1;
    if (!sortedCards[nextItemIndex]) {
      nextItemIndex = 0;
    }
    setCard(sortedCards[nextItemIndex]);
    currentIndex.current = nextItemIndex;
  }, [sortedCards]);

  const flip = () => {
    if (controls.current) controls.current.play();
    startTime.current = Date.now();
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      next();
    }, remainingTime.current);
  };

  const handlers = useSwipeable({
    onSwipedLeft: next,
    onSwipedRight: prev,
    preventScrollOnSwipe: true,

    onTouchStartOrOnMouseDown: () => setPaused(true),
    onTouchEndOrOnMouseUp: () => setPaused(false),
  });

  useEffect(() => {
    setCard(sortedCards[0]);
  }, [sortedCards]);

  useLayoutEffect(() => {
    calculateReadingTime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [card]);

  useLayoutEffect(() => {
    startTime.current = Date.now();
    remainingTime.current = cardTimeout;
    if (controls.current) {
      controls.current.complete();
      controls.current.play();
    }
    flip();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardTimeout]);

  useEffect(() => {
    if (paused) {
      remainingTime.current -= Date.now() - startTime.current;
      if (controls.current) controls.current.pause();
      if (timer.current) clearTimeout(timer.current);
    } else {
      flip();
    }

    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paused]);

  useEffect(() => {
    if (isInView) remainingTime.current = cardTimeout;
    setPaused(!isInView);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView]);

  useArrowKey((event) => {
    if (!isInView) return;
    if (event.key === "ArrowRight") {
      next();
    } else if (event.key === "ArrowLeft") {
      prev();
    }
  });

  return (
    <LazyMotion features={framerMotionFeatures}>
      <div
        ref={ref}
        className="relative">
        <Navigation
          index={currentIndex.current}
          items={sortedCards}
          setNext={next}
          setPrevious={prev}
        />
        <div
          onClick={() => next()}
          onMouseLeave={() => setPaused(false)}
          {...handlers}>
          <AnimatePresence
            mode="popLayout"
            initial={false}
            custom={direction}>
            {card && (
              <m.div
                key={`card-${card.id}`}
                variants={slideVariants}
                custom={direction}
                initial={"enter"}
                animate={"center"}
                exit={"leave"}>
                <blockquote
                  onMouseEnter={() => setPaused(true)}
                  onMouseLeave={() => setPaused(false)}>
                  <ClientInfo
                    {...card}
                    title={card[locale].title}
                    controls={controls}
                    timeout={cardTimeout}
                  />
                  <div
                    className={
                      "flex hyphens-auto whitespace-pre-wrap text-pretty font-secondary font-light italic text-primary/70 ~text-xl/3xl ~mt-4/6 ~leading-[2rem]/[2.75rem]"
                    }>
                    {card[locale].quote}
                  </div>
                </blockquote>
              </m.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </LazyMotion>
  );
};

const ClientInfo = ({
  name,
  title,
  link,
  company,
  photo,
  controls,
  timeout,
}: ClientInfoProps) => {
  return (
    <div
      className={
        "underline-links flex w-full items-center leading-tight ~gap-2/3"
      }>
      <div className="relative flex aspect-square w-20 shrink-0 skew-y-3 flex-col rounded-full p-[0.1875rem]">
        <Spinner
          controls={controls}
          timeout={timeout}
        />
        <div className="relative size-full flex-1 overflow-hidden rounded-full">
          {photo ? (
            <Image
              src={`${photo}`}
              fill
              className={`size-full bg-transparent object-cover object-top`}
              sizes={"74px"}
              alt={`${name} - ${title}`}
            />
          ) : (
            <div className="size-full bg-primary/10" />
          )}
        </div>
      </div>
      <div className="text-balance lg:max-w-prose">
        <p className="font-secondary text-primary ~text-sm/lg">{name}</p>
        <p className="text-primary/50 ~text-xs/sm">
          {title} at{" "}
          <Link
            className="hover:text-primary"
            href={link}
            target="_blank"
            rel="noopener">
            {company}
          </Link>
        </p>
      </div>
    </div>
  );
};

const Spinner = ({ controls, timeout }: SpinnerProps) => {
  const [circle, animate] = useAnimate();
  useEffect(() => {
    const initAnimation = () => {
      if (circle.current) {
        controls.current = animate(
          circle.current,
          {
            pathLength: 1,
            stroke: ["var(--clr-primary-dark)", "var(--clr-primary-dark)"],
          },
          { duration: timeout / 1000 }
        );
      }
    };
    if ("requestIdleCallback" in window) {
      requestIdleCallback(initAnimation);
    } else {
      setTimeout(initAnimation, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeout]);
  return (
    <>
      <svg
        width="100"
        height="100"
        viewBox="0 0 100 100"
        className="absolute left-0 top-0 z-10 size-20 -rotate-90">
        <circle
          cx="50"
          cy="50"
          r="48"
          pathLength="1"
          className="fill-none stroke-dark/0 stroke-[0.1875rem]"
        />
        <m.circle
          ref={circle}
          cx="50"
          cy="50"
          r="48"
          pathLength="0"
          className="fill-none stroke-[0.1875rem]"
        />
      </svg>
    </>
  );
};

const Navigation = ({
  index,
  items,
  setNext,
  setPrevious,
}: {
  index: number;
  items: Card[];
  setNext: () => void;
  setPrevious: () => void;
}) => {
  const t = useTranslations("common");

  return (
    <div className="relative z-10 flex items-center justify-start ~mb-4/6 ~gap-2/5 lg:absolute lg:right-0 lg:top-0 lg:mb-0">
      <button
        type="button"
        title={t("previous")}
        onClick={() => setPrevious()}
        className="group/nav flex aspect-square skew-y-3 items-center justify-center rounded-full border border-dark bg-white transition-colors duration-300 ~p-[0.6875rem]/5 hover:bg-dark">
        <FiArrowLeft className="-skew-y-3 text-primary/70 transition-all duration-300 ~text-lg/2xl group-hover/nav:text-white" />
      </button>
      <div className="flex font-secondary font-bold ~text-sm/base">
        <span className="min-w-5 text-right">{index + 1}</span>
        <span>/</span>
        <span className="min-w-5">{items.length}</span>
      </div>
      <button
        type="button"
        title={t("next")}
        onClick={() => setNext()}
        className="group/nav flex aspect-square skew-y-3 items-center justify-center rounded-full border border-dark bg-white transition-colors duration-300 ~p-[0.6875rem]/5 hover:bg-dark">
        <FiArrowRight className="-skew-y-3 text-primary/70 transition-all duration-300 ~text-lg/2xl group-hover/nav:text-white" />
      </button>
    </div>
  );
};

export default TestimonialsSwiper;
