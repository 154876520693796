export const items = [
  {
    id: 1,
    en: {
      quote:
        "Yves is a highly competent, innovative, and creative web developer. I am extremely satisfied with my website.",
      title: "Owner",
    },
    de: {
      quote:
        "Yves ist ein höchst kompetenter, innovativer und kreativer Web-Entwickler… Ich bin ausserordentlich zufrieden mit meiner Webseite.",
      title: "Inhaber",
    },
    name: "Franco Sciré",
    photo: "/testimonials/francoscire.jpg",
    company: "Haistylist Franco Sciré",
    link: "https://www.francoscire.ch",
  },
  {
    id: 2,
    en: {
      quote:
        "Yves made working together a pleasure. His competence, speed, and relentless drive for excellent results convinced me. I was particularly impressed by his attention to detail.",
      title: "Owner",
    },
    de: {
      quote:
        "Die Zusammenarbeit mit Yves war ein Vergnügen. Seine Kompetenz, Schnelligkeit und sein unermüdliches Streben nach hervorragenden Ergebnissen haben mich überzeugt. Besonders beeindruckt war ich von seiner Liebe zum Detail.",
      title: "Inhaber",
    },
    name: "Fredy Diener",
    photo: "/testimonials/fredy-diener_1.jpg?v=2",
    company: "Diener Leadership & Intercultural Coaching",
    link: "https://www.diener.coach",
  },
  {
    id: 3,
    en: {
      quote:
        "Yves Torres from MASSIF Web Studio perfectly brought our vision to life. His impressive creativity and top-notch professionalism truly amazed us. The collaboration was a complete success—I'm already looking forward to our next project together!",
      title: "CEO",
    },
    de: {
      quote:
        "Yves von MASSIF Web Studio hat unsere Vision perfekt umgesetzt. Mit beeindruckender Kreativität und erstklassiger Professionalität hat er uns begeistert. Die Zusammenarbeit war ein voller Erfolg – ich freue mich schon auf das nächste gemeinsame Projekt!",
      title: "Geschäftsführer",
    },
    name: "Adrian Burch",
    photo: "/testimonials/adrian-burch.jpg",
    company: "Oniko AG",
    link: "https://www.oniko.ch",
  },
  {
    id: 4,
    en: {
      quote:
        "Working with Yves is a pleasure. His professionalism, speed, and commitment to delivering exceptional results are impressive. Yves is the ideal partner to elevate your website and brand.",
      title: "Owner & Managing Director",
    },
    de: {
      quote:
        "Die Zusammenarbeit mit Yves ist top. Seine Professionalität, Schnelligkeit und sein Engagement, aussergewöhnliche Ergebnisse zu liefern, sind beeindruckend. Yves ist der ideale Partner, um Ihre Website und Ihre Marke aufzuwerten.",
      title: "Inhaber & Geschäftsführer",
    },
    name: "Ralf Krättli",
    photo: "/testimonials/ralf-kraettli.jpg",
    company: "Mediadschungel GmbH",
    link: "https://www.mediadschungel.ch",
  },
  {
    id: 5,
    en: {
      quote:
        "Yves was recommended to me by a friend. My project involved migrating the website to a new format. Yves was very helpful and pragmatic, and the entire transition went smoothly and flawlessly. I highly recommend Yves.",
      title: "Owner & Executive Coach",
    },
    de: {
      quote:
        "Yves wurde mir von einem Freund empfohlen. Mein Thema war die Migration der Webseite auf ein neues Format. Yves war sehr hilfreich und pragmatisch und die komplette Übernahme verlief fehlerfrei und zuverlässig. Ich kann Yves bestens empfehlen.",
      title: "Inhaber & Executive Coach",
    },
    name: "James Burke",
    photo: "/testimonials/james-burke.jpg",
    company: "JBEC – James Burke Executive Coaching",
    link: "https://www.jbexe-coaching.ch",
  },
  {
    id: 6,
    en: {
      quote:
        "Yves did an outstanding job with the design of the «Friends of Wildlife» website. His creative approach and eye for detail resulted in an engaging and user-friendly site. His professional and efficient work style was truly impressive. Highly recommended!",
      title: "Director",
    },
    de: {
      quote:
        "Yves hat das Design der «Friends of Wildlife»-Webseite hervorragend umgesetzt. Sein kreativer Ansatz und sein Auge fürs Detail haben eine ansprechende und benutzerfreundliche Seite geschaffen. Sein professionelle und effiziente Arbeitsweise war beeindruckend. Absolute Empfehlung!",
      title: "Geschäftsführerin",
    },
    name: "Karin Youssoufian",
    photo: "/testimonials/karin-youssoufian.jpg",
    company: "Friends of Wildlife Management",
    link: "https://friendsofwildlife.co.za",
  },
  {
    id: 7,
    en: {
      quote:
        "Yves is the perfect website partner in every way and has created several websites for my partners and me. Whether it’s consulting, design, functional user guidance, or programmatic solutions, Yves remains my go-to expert. Absolutely recommended!",
      title: "Owner & Managing Director",
    },
    de: {
      quote:
        "Yves ist in jeder Hinsicht der perfekte Webseiten-Partner und er erstellte schon mehrere Webseiten für meine Partner oder mich. Ob Beratung, Design, funktionale Nutzerführung oder auch für programmatische Lösungen ist und bleibt Yves für mich der richtige Ansprechpartner. Absolut empfehlenswert!",
      title: "Inhaber & Geschäftsführer",
    },
    name: "Ramon Schilt",
    photo: "/testimonials/ramon-schilt.jpg",
    company: "Schilt Immobilien GmbH",
    link: "https://www.schilt-immobilien.ch",
  },
  {
    id: 8,
    en: {
      quote:
        "Working with Yves is always a pleasure; his straightforward expertise is truly impressive.",
      title: "Partner & Industrial Designer",
    },
    de: {
      quote:
        "Die Zusammenarbeit mit Yves ist mir stets eine Freude, seine unkomplizierte Kompetenz ist bestechend.",
      title: "Partner & Industrial Designer",
    },
    name: "Stefan Maag",
    photo: "/testimonials/stefan-maag.png",
    company: "formscope GmbH",
    link: "https://www.formscope.ch/",
  },
  {
    id: 9,
    en: {
      quote:
        "We've been working wonderfully with Yves for many years. He is reliable and works quickly. Even in hectic times, Yves remains calm and keeps a clear overview. He consistently goes the extra mile to deliver beyond the standard, which is something we, as a design team, particularly appreciate.",
      title: "Owner",
    },
    de: {
      quote:
        "Mit Yves arbeiten wir seit vielen Jahren wunderbar zusammen. Er ist verlässlich und arbeitet schnell. Wenn es hektisch wird, bewahrt Yves die Ruhe und behält die Übersicht. Er legt ein grosses Engagement an den Tag, um noch etwas mehr herauszuholen, über den Standard hinaus. Das schätzen wir als Designteam besonders",
      title: "Inhaberin",
    },
    name: "Helen Hüsser",
    photo: "/testimonials/helen-huesser.jpg",
    company: "Notice Design GmbH",
    link: "https://www.notice.design/",
  },
  {
    id: 10,
    en: {
      quote:
        "Collaborating with Yves was a thoroughly enjoyable experience. His professionalism, adaptability, and dedication were remarkable. If you're considering optimizing or redesigning your website, look no further.",
      title: "Managing Director",
    },
    de: {
      quote:
        "Die Zusammenarbeit mit Yves war äusserst angenehm. Seine Professionalität, Flexibilität und Leidenschaft für herausragende Ergebnisse waren beeindruckend. Yves' Begeisterung in jedem Entwicklungsabschnitt hebt sich besonders hervor. Wenn es um die Optimierung oder Neugestaltung Ihrer Website geht, ist Yves der ideale Partner.",
      title: "Geschäftsführerin",
    },
    name: "Sabrina Klingelhöfer",
    photo: "/testimonials/sabrina-klingelhoefer.jpg",
    company: "Kinderhaus St. Josef",
    link: "https://www.kinderhaus-chur.ch/",
  },
  {
    id: 11,
    en: {
      quote:
        "My collaboration with Yves from 2020 to 2024 across various projects and tasks was always extremely pleasant. His professionalism, efficiency, and dedication were remarkable. Every idea was thoroughly considered and tailored to our needs; every issue was addressed and resolved in the shortest possible time. Communication was always clear and transparent, making the collaboration seamless. \n\nYves' commitment to quality is particularly noteworthy, consistently exceeding our expectations. I highly recommend Yves/MASSIF Web Studio!",
      title:
        "Scientific Assistant, Degree Program Management, Cooperative Master's Program in Social Work",
    },
    de: {
      quote:
        "Meine Zusammenarbeit mit Yves während 2020 bis 2024 im Rahmen verschiedener Projekte und Aufgaben war immer äusserst angenehm. Seine Professionalität, Effizienz und sein Engagement waren bemerkenswert. Jede Idee wurde durchdacht und auf unsere Bedürfnisse abgestimmt; jedes Problem innert kürzester Zeit angegangen und behoben. Die Kommunikation war jederzeit klar und transparent, was die Zusammenarbeit reibungslos machte.\n\nBesonders hervorzuheben ist Yves' Einsatz für die Qualität der Arbeit, die unsere Erwartungen übertroffen hat. Ich empfehle Yves/Studio MASSIF daher wärmstens!",
      title:
        "Wissenschaftliche Mitarbeiterin, Studiengangleitung, Kooperativer Master Soziale Arbeit",
    },
    name: "Anne-Marie Weist",
    company: "BFH, HSLU, and OST",
    link: "https://www.masterinsozialerarbeit.ch/",
  },
  {
    id: 12,
    en: {
      quote:
        "I am extremely satisfied with our collaboration with Yves. His response times by email or phone are impressively fast. He always delivers competently and promptly. The friendly cooperation at eye level, his calm and considered manner and his ability to speak INP's language make the collaboration extremely pleasant.",
      title: "Recruiting & Employer Branding",
    },
    de: {
      quote:
        "Ich bin äusserst zufrieden mit unserer Zusammenarbeit mit Yves. Seine Reaktionszeiten per Mail oder Telefon sind beeindruckend schnell. Er liefert stets kompetent und prompt. Die freundliche Zusammenarbeit auf Augenhöhe, seine ruhige und überlegte Art sowie seine Fähigkeit, die Sprache von INP zu sprechen, machen die Zusammenarbeit äusserst angenehm.",
      title: "Recruiting & Employer Branding",
    },
    name: "Rahel Gander",
    photo: "/testimonials/rahel-gander.jpg",
    company: "INP Schweiz AG",
    link: "https://www.inp-e.ch/",
  },
  {
    id: 13,
    en: {
      quote:
        "Working with Yves is a great experience through and through! Professional, fast and with incredible commitment, he designs and maintains the perfect website and fulfils all wishes. Absolutely recommendable!",
      title: "Head of Finance",
    },
    de: {
      quote:
        "Mit Yves zusammenzuarbeiten ist durch und durch eine tolle Erfahrung! Professionell, schnell und mit unglaublichem Engagement gestaltet und pflegt er die perfekte Website und kommt allen Wünschen nach. Absolut empfehlenswert!",
      title: "Bereichsleiterin Finanzen",
    },
    name: "Angela Suter",
    photo: "/testimonials/angela-suter.jpg",
    company: "Verein Zürcher Finanzfachleute / Gemeinde Regensdorf",
    link: "https://www.vzf.ch/",
  },
  {
    id: 14,
    de: {
      quote:
        "Die Zusammenarbeit mit Yves macht richtig Freude. Er hat eine schnelle Auffassungsgabe, ist sehr prompt, immer erreichbar, freundlich und hilfsbereit. Auch die Resultate seiner Arbeit können sich sehen lassen - sehr professionell!",
      title: "Natur- und Umweltfachmann",
    },
    en: {
      quote:
        "Working with Yves is a real pleasure. He has a quick grasp, is very prompt, always available, friendly, and helpful. The results of his work are also impressive - very professional!",
      title: "Nature and Environment Specialist",
    },
    name: "Michael Lauber",
    company: "Xonotech GmbH",
    link: "https://www.xonotech.ch/",
  },
  {
    id: 15,
    de: {
      quote:
        "Wir arbeiten sehr gerne mit Yves zusammen. Seine Professionalität und sein Engagement sind beeindruckend. Auch nach Projektabschluss hilft Yves bei Problemen und Fragen immer rasch und unkompliziert weiter. Darum können wir uns für unseren Webauftritt keinen idealeren Partner vorstellen!",
      title: "Dipl. Architekt ETH SIA, Partner",
    },
    en: {
      quote:
        "We enjoy working with Yves very much. His professionalism and commitment are impressive. Even after the project is completed, Yves always helps quickly and easily with problems and questions. That's why we can't imagine a better partner for our web presence!",
      title: "Certified Architect ETH SIA, Partner",
    },
    name: "Cyril Kramer",
    photo: "/testimonials/cyril-kramer.jpg",
    company: "Diagonal Architekten AG",
    link: "https://www.diagonal.ch/",
  },
  {
    id: 16,
    de: {
      quote:
        "Lieber Yves, herzlichen Dank für die tolle Arbeit an unserer Webseite! Seit 20 Jahren arbeiten wir immer wieder gerne mit dir zusammen, und jedes Mal sind wir aufs Neue begeistert. Du verstehst es, unsere Wünsche perfekt umzusetzen, und die Ergebnisse sind stets top! Wir schätzen deine Professionalität und Kreativität sehr. Vielen Dank für alles – auf viele weitere Jahre der Zusammenarbeit!",
      title: "Inhaber, Geschäftsleitung, Projektentwicklung Design",
    },
    en: {
      quote:
        "Dear Yves, thank you very much for the great work on our website! For 20 years, we have always enjoyed working with you again and again, and every time we are thrilled anew. You understand how to implement our wishes perfectly, and the results are always top-notch! We appreciate your professionalism and creativity very much. Thank you for everything – here's to many more years of collaboration!",
      title: "Owner, Management, Design Project Development",
    },
    name: "Adrian Haslimeier",
    photo: "/testimonials/adrian-haslimeier.jpg",
    company: "Bogen Design GmbH",
    link: "https://www.bogen.ch/",
  },
  {
    id: 17,
    de: {
      quote:
        "Die Zusammenarbeit mit Yves ist sehr speditiv und partnerschaftlich. Anpassungswünsche geht er sofort an und sucht nach der effizientesten Lösung. So ist es kein Zufall, dass wir mit der CMS-Lösung sehr zufrieden und die Kosten für die Wartung überschaubar sind.",
      title: "Betrieblicher Leiter",
    },
    en: {
      quote:
        "Working with Yves is very efficient and cooperative. He immediately addresses adaptation requests and looks for the most efficient solution. It is no coincidence that we are very satisfied with the CMS solution and that the maintenance costs are manageable.",
      title: "Operational Manager",
    },
    name: "Christian Kaufmann",
    company: "Central Praxis AG",
    link: "https://www.central-praxis.ch/",
  },
  {
    id: 18,
    de: {
      quote:
        "Yves hat unsere Erwartungen nicht nur erfüllt, sondern übertroffen. Mit seiner klaren Kommunikation, seinem kreativen Ansatz und seiner Präzision hat er unser Projekt auf ein neues Level gehoben. Seine Fähigkeit, Ideen zielgerichtet umzusetzen, macht ihn zu einem echten Gewinn für jede Zusammenarbeit.",
      title: "Geschäftsleiter / Projektleiter",
    },
    en: {
      quote:
        "Yves has not only met but exceeded our expectations. With his clear communication, creative approach, and precision, he has taken our project to a new level. His ability to implement ideas in a targeted manner makes him a real asset to any collaboration.",
      title: "Managing Director / Project Manager",
    },
    name: "Patrick Moehrle",
    photo: "/testimonials/patrick-moehrle.jpg",
    company: "Müller & Partner AG Architekten + Planer",
    link: "https://www.mp-arch.ch/",
  },
  {
    id: 19,
    de: {
      quote:
        "Yves zeichnet sich durch seine dienstleistungsorientierte, kompetente und engagierte Arbeitsweise aus. Er kommuniziert stets klar und bleibt geduldig, was die Zusammenarbeit mit ihm angenehm und unkompliziert macht.",
      title: "Leitung Schuldenprävention",
    },
    en: {
      quote:
        "Yves stands out for his service-oriented, competent, and dedicated way of working. He always communicates clearly and remains patient, making working with him pleasant and uncomplicated.",
      title: "Head of Debt Prevention",
    },
    name: "Matthias Felix",
    company: "Schuldenberatung Kanton Zürich",
    link: "https://www.schulden-zh.ch/",
  },
  {
    id: 20,
    de: {
      quote:
        "Dank dem grossen Know How von Yves und seiner authentischen Passion zum Beruf war unsere Zusammenarbeit sehr effizient und unkompliziert. Er hat meine Wünsche perfekt verstanden und noch viel schöner umgesetzt als erhofft!",
      title: "Coach- und Hypnosetherapeutin",
    },
    en: {
      quote:
        "Thanks to Yves' great know-how and his authentic passion for his profession, our collaboration was very efficient and uncomplicated. He understood my wishes perfectly and implemented them even more beautifully than hoped!",
      title: "Coach and Hypnotherapist",
    },
    name: "Marina Hauser",
    photo: "/testimonials/marina-hauser.jpg",
    company: "Hypnohauser",
    link: "https://www.hypnohauser.ch/",
  },
  {
    id: 21,
    de: {
      quote:
        "MASSIF Webdesign Studio hat meine Vision für eine professionelle und ansprechende Webseite perfekt umgesetzt. Die Zusammenarbeit war reibungslos und das Ergebnis übertrifft meine Erwartungen bei weitem.",
      title: "Leiter Visualisierungen",
    },
    en: {
      quote:
        "MASSIF Webdesign Studio perfectly implemented my vision for a professional and appealing website. The collaboration was smooth, and the result far exceeded my expectations.",
      title: "Head of Visualizations",
    },
    name: "Christian Rutschmann",
    photo: "/testimonials/christian-rutschmann.jpg",
    company: "Business Images AG",
    link: "https://www.businessimages.ch/",
  },
  {
    id: 22,
    de: {
      quote:
        "Yves hat uns mit seinem Fachwissen stets zuverlässig und schnell unterstützt. Seine professionelle Beratung und Lösungskompetenz haben unsere Projekte entscheidend verbessert. Wir waren mit seiner Arbeit immer sehr zufrieden und schätzen die Zusammenarbeit sehr.",
      title: "Senior Operations Manager",
    },
    en: {
      quote:
        "Yves has always supported us reliably and quickly with his expertise. His professional advice and problem-solving skills have significantly improved our projects. We have always been very satisfied with his work and greatly appreciate the collaboration.",
      title: "Senior Operations Manager",
    },
    name: "Fabienne Baumgartner",
    photo: "/testimonials/fabienne-baumgartner.jpg",
    company: "Publicis Media Schweiz AG",
    link: "https://www.publicismedia.ch/",
  },
  {
    id: 23,
    de: {
      quote:
        "Die Zusammenarbeit mit Yves war von Anfang bis Ende überzeugend. Neben seiner Kreativität und Professionalität hat uns besonders seine transparente und effiziente Preisstruktur beeindruckt. Er arbeitet zielorientiert, zuverlässig und mit einem Auge fürs Detail – ein Partner, den ich uneingeschränkt weiterempfehlen kann.",
      title: "Geschäftsinhaber, Leitender Arzt",
    },
    en: {
      quote:
        "The collaboration with Yves was convincing from start to finish. In addition to his creativity and professionalism, we were particularly impressed by his transparent and efficient pricing structure. He works in a goal-oriented, reliable manner with an eye for detail – a partner I can recommend without reservation.",
      title: "Business Owner, Senior Physician",
    },
    name: "Dr. med. Mathias Dolder",
    photo: "/testimonials/mathias-dolder.jpg",
    company: "Central Praxis AG",
    link: "https://www.central-praxis.ch/",
  },
  {
    id: 24,
    de: {
      quote:
        "Die Zusammenarbeit mit Yves war stets sehr professionell. Yves versteht es, Designs pixelgenau und screenübergreifend umzusetzen. Somit brauchte es jeweils keine Korrekturrunden, was unsere Effizienz massi(f) steigerte.",
      title: "Partner",
    },
    en: {
      quote:
        "The collaboration with Yves was always very professional. Yves knows how to implement designs pixel-perfect and cross-screen. This eliminated the need for correction rounds, which massively increased our efficiency.",
      title: "Partner",
    },
    name: "Stephan Hafner",
    photo: "/testimonials/stephan-hafner.jpg",
    company: "Koller Direct AG",
    link: "https://www.kollerdirect.ch/",
  },
  {
    id: 25,
    de: {
      quote:
        "Yves ist kreativ, professionell und arbeitet mit viel Freude. Er hat unsere Produkte perfekt in Szene gesetzt und damit unsere Erwartungen an die Webseite bei weitem übertroffen. Wir können Ihn aus Überzeugung weiterempfehlen.",
      title: "Geschäftsführer / Inhaber",
    },
    en: {
      quote:
        "Yves is creative, professional, and works with great joy. He has perfectly showcased our products, far exceeding our expectations for the website. We can recommend him with conviction.",
      title: "Managing Directors / Owners",
    },
    name: "Nicolas Kunz & Sergio Pasinelli",
    photo: "/testimonials/nicolas-kunz-sergio-pasinelli.jpg",
    company: "Hornatelier Bucher GmbH",
    link: "https://www.hornatelier.ch/",
  },
  {
    id: 26,
    de: {
      quote:
        "Seit Jahren schätze ich die persönliche, kreative und engagierte Zusammenarbeit mit Yves. Herzlichen Dank.",
      title: "Inhaberin / Geschäftsleitung",
    },
    en: {
      quote:
        "For years, I have appreciated the personal, creative, and dedicated collaboration with Yves. Thank you very much.",
      title: "Owner / Management",
    },
    name: "Claudia Ott",
    photo: "/testimonials/claudia-ott.jpg",
    company: "Ottpunkt AG",
    link: "https://www.ottpunkt.ch/",
  },
  {
    id: 27,
    de: {
      quote:
        "Die Zusammenarbeit mit Yves ist sehr angenehm und extrem hochwertig. Seine Professionalität, schnelle Arbeitsweise und sein Engagement für qualitative Ergebnisse haben überzeugt. Yves hat sich in jeder Phase der Entwicklung zuverlässig eingebracht. Wenn Sie Ihre Website und Marke verbessern möchten, ist Yves eine gute Wahl.",
      title: "Booking, Kommunikation & Marketing",
    },
    en: {
      quote:
        "Working with Yves is very pleasant and extremely high quality. His professionalism, fast work, and commitment to quality results convinced us. Yves has been reliable in every phase of development. If you want to improve your website and brand, Yves is a good choice.",
      title: "Booking, Communication & Marketing",
    },
    name: "Marcel Häfliger",
    company: "WERKK Baden",
    link: "https://werkk-baden.ch/",
  },
  {
    id: 28,
    de: {
      quote:
        "Yves denkt immer mit und ist technisch topfit. Wir haben gemeinsam schon viele grosse und kleine Projekte realisiert.",
      title: "Inhaber",
    },
    en: {
      quote:
        "Yves always thinks along and is technically top fit. Together we have already realized many large and small projects.",
      title: "Owner",
    },
    name: "Gilles Bachmann",
    photo: "/testimonials/gilles-bachmann.jpg",
    company: "Building Brands GmbH",
    link: "https://www.buildingbrands.ch/",
  },
  {
    id: 29,
    de: {
      quote:
        "Die Entwicklung von unserer Webseite hat Yves mit viel Engagement und Know-How umgesetzt. Wir haben seine wertvolle Beratung und die schnelle lösungsorientierte Arbeitsweise sehr geschätzt. Mit dem Resultat sind wir sehr zufrieden und empfehlen Yves gerne als idealen Partner für die Aufwertung ihrer Webseite.",
      title: "Co-founder, Industrial Designer",
    },
    en: {
      quote:
        "Yves implemented the development of our website with great commitment and know-how. We greatly appreciated his valuable advice and fast solution-oriented work. We are very satisfied with the result and are happy to recommend Yves as the ideal partner for upgrading your website.",
      title: "Co-founder, Industrial Designer",
    },
    name: "Thomas Burkard",
    photo: "/testimonials/thomas-burkard.jpg",
    company: "Bernhard Burkard GmbH",
    link: "https://www.bernhard-burkard.com/",
  },
  {
    id: 30,
    de: {
      quote:
        "Programmiertechnisch top, mitdenkend, absolut zuverlässig, clever – und das grosse Plus – Yves hat ein gutes visuelles Auge. Ich fühle mich mit Yves als Programmierer an meiner Seite rundum super aufgehoben.",
      title: "Inhaberin",
    },
    en: {
      quote:
        "Top in programming, thoughtful, absolutely reliable, clever – and the big plus – Yves has a good visual eye. I feel super well looked after with Yves as a programmer by my side.",
      title: "Owner",
    },
    name: "Diana Schneider",
    photo: "/testimonials/diana-schneider.jpg",
    company: "Typo+Grafik, Aarau",
    link: "https://www.typoundgrafik.ch/",
  },
  {
    id: 31,
    de: {
      quote:
        "Unsere Zusammenarbeit mit Yves war auf einem sehr hohen Niveau professionell und zielgerichtet. Sein zuvorkommender Support und seine Beratung sind für uns ein wichtiges Kriterium und eine grosse Hilfe für eine funktionierende Website bei der schnelllebigen Umgebung. Yves ist der Ideale Partner für die Programmierung Ihrer Website.",
      title: "CEO & Founder",
    },
    en: {
      quote:
        "Our collaboration with Yves was very professional and goal-oriented at a very high level. His courteous support and advice are an important criterion and a great help for us in the fast-paced environment. Yves is the ideal partner for programming your website.",
      title: "CEO & Founder",
    },
    name: "Marco Buholzer",
    photo: "/testimonials/marco-buholzer.jpg",
    company: "Out of the Box Consulting GmbH",
    link: "https://www.mikebravo.ch/",
  },
  {
    id: 34,
    de: {
      quote:
        "Die Zusammenarbeit mit Yves Torres ist sehr unkompliziert und zielführend. Seine Professionalität, sein Engagement und Streben die Kundenbedürfnisse zu übertreffen sind allgegenwärtig. Für die Umsetzung einer individuellen Webseite ist Yves der ideale Partner.",
      title: "Geschäftsführer",
    },
    en: {
      quote:
        "Working with Yves Torres is very straightforward and goal-oriented. His professionalism, commitment, and ambition to exceed customer needs are omnipresent. For the implementation of an individual website, Yves is the ideal partner.",
      title: "Managing Director",
    },
    name: "Andreas Zäch",
    photo: "/testimonials/andreas-zaech.jpg",
    company: "AZ Immo AG",
    link: "https://www.azimmobilien.ch/",
  },
  {
    id: 35,
    de: {
      quote:
        "Die Zusammenarbeit mit Yves ist top! Er ist schnell, professionell, innovativ und immer freundlich. Ich bin glücklich, konnte ich meine Webseite mit ihm realisieren, er ist das perfekte Gegenüber!",
      title: "Künstlerin und Kuratorin",
    },
    en: {
      quote:
        "Working with Yves is top! He is fast, professional, innovative, and always friendly. I am happy to have been able to realize my website with him, he is the perfect counterpart!",
      title: "Artist and Curator",
    },
    name: "Sadhyo Niederberger",
    photo: "/testimonials/sadhyo-niederberger.jpg",
    company: "Sadhyo",
    link: "https://www.sadhyo.ch/",
  },
  {
    id: 36,
    de: {
      quote:
        "Wir möchten unserem talentierten Programmierer Yves für seine herausragende Arbeit danken. Dank seiner Kreativität und seines Fachwissens erstrahlt unsere Webseite in neuem Glanz. Die Benutzerfreundlichkeit und das ansprechende Design machen den Besuch zu einem echten Erlebnis.",
      title: "Assistentin",
    },
    en: {
      quote:
        "We would like to thank our talented programmer Yves for his outstanding work. Thanks to his creativity and expertise, our website shines in new splendor. The user-friendliness and appealing design make the visit a real experience.",
      title: "Assistant",
    },
    name: "Jing Zhou",
    photo: "/testimonials/jing-zhou-n.jpg",
    company: "Wesley's Kitchen",
    link: "https://www.wesleys-kitchen.ch/",
  },
  {
    id: 37,
    de: {
      quote:
        "Yves erstellt und gestaltete unseren Internetauftritt, inklusive Logo. Pragmatische Lösungen, klare Kommunikation, Super Ergebniss! Bin komplett Zufrieden!",
      title: "Inhaber & Filmproduzent",
    },
    en: {
      quote:
        "Yves created and designed our website, including the logo. Pragmatic solutions, clear communication, great result! Completely satisfied!",
      title: "Owner & Film Producer",
    },
    name: "Kaspar Winkler",
    photo: "/testimonials/kaspar-winkler.jpg",
    company: "Tilt Production",
    link: "https://www.tiltproduction.ch/",
  },
  {
    id: 38,
    de: {
      quote:
        "Seit 12 Jahren vertraue ich auf Yves' Kreativität und Professionalität. Mit viel Enthusiasmus verwandelt Yves jede Herausforderung in frische, innovative Konzepte. Seine Zuverlässigkeit und Leidenschaft machen ihn zu einem unverzichtbaren Partner. Einfach top!",
      title: "Marketing & Kommunikation",
    },
    en: {
      quote:
        "For 12 years I have relied on Yves' creativity and professionalism. With great enthusiasm, Yves transforms every challenge into fresh, innovative concepts. His reliability and passion make him an indispensable partner. Simply top!",
      title: "Marketing & Communication",
    },
    name: "Jennifer Lewandowski",
    photo: "/testimonials/jennifer-lewandowski.jpg",
    company: "INP Schweiz AG",
    link: "https://www.inp-e.ch/",
  },
  {
    id: 39,
    de: {
      quote:
        "Wir arbeiten seit vielen Jahren mit Yves zusammen und sind immer absolut zufrieden!\n\nSeine Kreativität und seine Fähigkeit, sich sowohl in uns als Anwender:innen als auch in die Bedürfnisse der Nutzer:innen unserer Website hineinzuversetzen, haben einen äusserst positiven Einfluss auf die Gestaltung und Benutzerfreundlichkeit unseres Webauftritts.\n\nWir schätzen ausserdem sehr die offene, schnelle und persönliche Kommunikation mit Yves.",
      title: "Geschäftsleiterin",
    },
    en: {
      quote:
        "We have been working with Yves for many years and are always absolutely satisfied!\n\nHis creativity and his ability to put himself in our shoes as users as well as in the needs of the users of our website have an extremely positive impact on the design and user-friendliness of our web presence.\n\nWe also greatly appreciate the open, fast, and personal communication with Yves.",
      title: "Managing Director",
    },
    name: "Nina Kovacs",
    photo: "/testimonials/nina-kovacs.jpg",
    company: "Vinca Film GmbH",
    link: "https://www.vincafilm.ch/",
  },
  {
    id: 40,
    de: {
      quote:
        "Die Website bereitet mir immer noch Freude. Yves hat meine «Eigenheiten» intuitiv erfasst und verstand es, diese grafisch ins Projekt einzubringen. Auch schätze ich an ihm, dass er für Laien etwas komplexe, technische Sachverhalte stets gut verständlich zu erklären weiss.",
      title:
        "Historikerin (lic. phil./MA UZH), Guide, Fotografin, Referentin und Autorin",
    },
    en: {
      quote:
        "The website still gives me pleasure. Yves intuitively grasped my 'peculiarities' and knew how to incorporate them graphically into the project. I also appreciate that he knows how to explain somewhat complex, technical issues in a way that is easy to understand for laymen.",
      title:
        "Historian (lic. phil./MA UZH), Guide, Photographer, Speaker and Author",
    },
    name: "Sandra Walser",
    photo: "/testimonials/sandra-walser.jpg",
    company: "Sandra Walser",
    link: "https://www.sandrawalser.ch/",
  },
  {
    id: 41,
    de: {
      quote:
        "Die Zusammenarbeit mit Yves war überaus bereichernd. Seine Kreativität, Zuverlässigkeit und der unermüdliche Einsatz für exzellente Ergebnisse haben mich begeistert. Yves bringt frische Ideen und Leidenschaft in jedes Projekt ein. Für innovative Lösungen und eine erfolgreiche Zusammenarbeit ist Yves die perfekte Wahl.",
      title: "Geschäftsleitung",
    },
    en: {
      quote:
        "The collaboration with Yves was extremely enriching. His creativity, reliability, and tireless commitment to excellent results impressed me. Yves brings fresh ideas and passion to every project. For innovative solutions and successful collaboration, Yves is the perfect choice.",
      title: "Management",
    },
    name: "Silvia Hürlimann",
    photo: "/testimonials/silvia-huerlimann.jpg",
    company: "Kita Ennethüsli",
    link: "https://kita-ennethuesli.ch/",
  },
  {
    id: 42,
    de: {
      quote:
        "Yves Torres hat ein sehr gutes Gespür für die Bedürfnisse der Nutzer. Beeindruckend ist sein scharfes Auge für Details, seine Professionalität und Sorgfalt. Das Ergebnis ist herausragend und macht viel Freude.",
      title: "Stiftungsratspräsidentin",
    },
    en: {
      quote:
        "Yves Torres has a very good sense for the needs of users. Impressive is his sharp eye for details, his professionalism and care. The result is outstanding and very enjoyable.",
      title: "President of the Board of Trustees",
    },
    name: "Christine von Arx",
    photo: "/testimonials/christine-von-arx.jpg",
    company: "Villa Sonnenberg",
    link: "https://www.villasonnenberg.ch/",
  },
  {
    id: 43,
    de: {
      quote:
        "Die Zusammenarbeit mit massif.ch, beziehungsweise Yves ist immer sehr unkompliziert und zielführend. Unsere Anliegen, Wünsche und Anpassungen werden stets professionell und einwandfrei umgesetzt und wir schätzen die Zusammenarbeit sehr. ",
      title: "Verkauf & Marketing",
    },
    en: {
      quote:
        "The collaboration with massif.ch, or Yves, is always very straightforward and goal-oriented. Our concerns, wishes and adjustments are always implemented professionally and flawlessly, and we greatly appreciate the collaboration.",
      title: "Sales & Marketing",
    },
    name: "Gloria Humbel",
    photo: "/testimonials/gloria-humbel.jpg",
    company: "Humbel Spezialitätenbrennerei AG",
    link: "https://www.humbel.ch/",
  },
  {
    id: 44,
    de: {
      quote:
        "Yves ist für uns ein absolut zuverlässiger und kompetenter Partner, wenn es um eine Änderung oder Neugestaltung unserer Webseite geht. Unsere Wünsche werden von ihm innert kurzer Zeit professionell umgesetzt. Wir schätzen zudem seine ruhige, kompetente Arbeitsweise. Die kreative Zusammenarbeit mit Yves dauert mittlerweile mehrere Jahre und wir können ihn bestens weiterempfehlen.",
      title: "Fachanwältin SAV Familienrecht & Partnerin",
    },
    en: {
      quote:
        "Yves is an absolutely reliable and competent partner for us when it comes to changing or redesigning our website. Our requests are professionally implemented by him within a short time. We also appreciate his calm, competent way of working. The creative collaboration with Yves has been going on for several years now and we can highly recommend him.",
      title: "Specialist Lawyer SAV Family Law & Partner",
    },
    name: "Esther Küng",
    photo: "/testimonials/esther-kueng.jpg",
    company: "Küng Metzler Küng Familienkanzlei",
    link: "https://www.kmtbaden.ch/",
  },
  {
    id: 45,
    de: {
      quote:
        "Die Zusammenarbeit mit Yves ist ein Vergnügen. Mit seiner Professionalität ist er der ideale Partner für erstklassige Weblösungen.",
      title: "Corporate Identity",
    },
    en: {
      quote:
        "Working with Yves is a pleasure. With his professionalism, he is the ideal partner for first-class web solutions.",
      title: "Corporate Identity",
    },
    name: "Stephanie Baumgartner",
    photo: "/testimonials/stephanie-baumgartner.jpg",
    company: "Fondarex SA",
    link: "https://www.fondarex.com/",
  },
  {
    id: 46,
    de: {
      quote:
        "Die Zusammenarbeit war super angenehm. Yves ist sympathisch, unkompliziert und kompetent. Er reagiert immer schnell auf Anfragen, setzt Anpassungen in kurzer Zeit um und nimmt sich gleichzeitig ausreichend Zeit, um auf uns einzugehen. So dass wir uns eben richtig gut aufgehoben fühlen.",
      title: "Marketingassistentin",
    },
    en: {
      quote:
        "The collaboration was super pleasant. Yves is likeable, uncomplicated and competent. He always responds quickly to inquiries, implements adjustments in a short time and at the same time takes enough time to respond to us. So that we feel really well taken care of.",
      title: "Marketing Assistant",
    },
    name: "Cindy Morgenthaler",
    company: "WALDIS Tresore AG",
    link: "https://www.tresore.ch",
  },
  {
    id: 47,
    de: {
      quote:
        "Die Zusammenarbeit mit MASSIF Web Studio schätzen wir sehr. Yves ist zuverlässig, kreativ und hat immer ein offenes Ohr für unsere Wünsche. Unsere Ideen für den neuen Webauftritt hat er nicht nur verstanden, sondern perfekt umgesetzt – technisch und optisch. Auch spontane Anpassungen erledigt er schnell und unkompliziert. Wir können MASSIF Web Studio definitiv weiterempfehlen!",
      title: "Architektin / Projektleiterin Kommunikation",
    },
    en: {
      quote:
        "We greatly appreciate working with MASSIF Web Studio. Yves is reliable, creative, and always has an open ear for our wishes. He not only understood our ideas for the new website, but also implemented them perfectly - technically and visually. He also makes spontaneous adjustments quickly and easily. We can definitely recommend MASSIF Web Studio!",
      title: "Architect / Project Manager Communication",
    },
    name: "Nadia Kuhn-Prohic",
    photo: "/testimonials/nadia-kuhn-prohic.jpg",
    company: "KMP Architektur AG",
    link: "https://www.kmpag.ch/",
  },
];
