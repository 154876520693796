import { useEventListener } from "usehooks-ts";

export const useArrowKey = (callback: (_event: KeyboardEvent) => void) => {
  const handleArrow = (event: KeyboardEvent) => {
    if (event.key === "ArrowRight" || event.key === "ArrowLeft") {
      callback(event);
    }
  };

  useEventListener("keydown", handleArrow);
};
